<template>
  <b-container fluid>
    <loading-inline
      v-if="!data_ready"
      class="d-flex justify-content-center mx-auto w-100"
    ></loading-inline>

    <b-card v-else class="aggregator" no-body>
      <h2 class="text-left">
        {{ object_name }}
      </h2>

      <div v-if="!anonym_project">
        <gamelink
          v-for="(link, index) in gamelinks"
          :key="index"
          :gamelink="link"
        ></gamelink>
      </div>

      <b-tabs
        v-if="
          this.candidates.length > 0 || Object.keys(requestParams).length > 0
        "
      >
        <b-tab class="tab" active :title="$t('gameplay-table.applicants')">
          <div v-if="allowCSV">
            <div class="csv">
              <button
                class="btn btn-success btn-sm"
                @click="generateCSV"
                :disabled="!enableCSVButton"
              >
                {{ $t("generate_csv") }}
              </button>
            </div>
            <b-modal
              centered
              id="csv-modal"
              size="sm"
              v-model="showCSVPopup"
              :title="$t('generate_csv')"
              hide-footer
            >
              <p v-if="csvSuccess">{{ $t("csv_success_text") }}</p>
              <div v-else>
                <b-alert show variant="danger"
                  >{{ $t("csv_error_text", { code: csvErrorCode }) }}
                </b-alert>
              </div>
              <div class="modal-footer">
                <b-button
                  :variant="csvSuccess ? 'primary' : 'outline-primary'"
                  @click.stop="showCSVPopup = false"
                >
                  {{ $t("popup_close") }}
                </b-button>
                <b-button
                  v-if="!csvSuccess"
                  variant="success"
                  :disabled="!enableCSVButton"
                  @click.stop="generateCSV()"
                >
                  {{ $t("retry") }}
                </b-button>
              </div>
            </b-modal>
          </div>
          <filter-dates
            :language="user_lang"
            @on-search-by-dates="setDateFilter"
            :toggleButtonText="$t('filter-component.button_title_gameplays')"
            searchFor="CandidatesList"
          ></filter-dates>

          <div class="legend">
            <div
              v-for="category in statusCategories"
              :key="category.key"
              :id="`category-label-${category.key}`"
              class="category"
              @click="
                search_values.status = category.class;
                setFilter(true);
              "
            >
              <p
                v-if="isVisible(category.class)"
                :class="`completeness-${category.class} ${
                  search_values.status === category.class ? 'selected' : ''
                }`"
              >
                {{ $t(`dashboard.statistics_tab.legend.${category.key}`) }}
              </p>
              <b-popover
                placement="top"
                :target="`category-label-${category.key}`"
                triggers="hover"
                custom-class="completeness-tooltip"
              >
                {{ $t(`dashboard.statistics_tab.legend.${category.key}_desc`) }}
              </b-popover>
            </div>
            <div
              v-if="search_values.status"
              class="category"
              @click="
                search_values.status = null;
                setFilter(true);
              "
            >
              <img
                class="warning-icon"
                src="/img/remove-filter-icon.svg"
                :title="$t('filter-component.clear')"
              />
            </div>
          </div>
          <div class="competencies-checkbox">
            <input
              id="competencies"
              type="checkbox"
              name="competencies"
              v-model="show_competencies"
            />

            <label for="competencies">
              {{ $t("gameplay-table.show_competencies") }}
            </label>
          </div>

          <p class="display-options">
            {{ $t("gameplay-table.pagination.rows") }}
          </p>
          <button class="btn-sm filter" @click="setPagesize(10)">10</button>
          <button class="btn-sm filter" @click="setPagesize(20)">20</button>
          <button class="btn-sm filter" @click="setPagesize(50)">50</button>
          <button class="btn-sm filter" @click="setPagesize(100)">100</button>
          <div class="pages display-options">
            {{ $t("gameplay-table.pagination.pages") }} {{ currentPage }} /
            {{ pageCount }}
          </div>
          <pagination
            :class="{ 'pagination-disable': table_updating }"
            v-model="currentPage"
            :records="total"
            :per-page="perPage"
            :options="paginationOptions"
            @paginate="setPage"
          ></pagination>

          <div class="table-responsive gameplay-table">
            <loading-inline
              v-if="table_updating"
              class="d-flex justify-content-center mx-auto w-100 loading-indicator"
            ></loading-inline>

            <table
              class="table"
              :class="{ 'table-loading-blur': table_updating }"
            >
              <!-- table header -->
              <thead>
                <tr>
                  <gameplay-table-header
                    v-for="(p, index) in profile_match_headers"
                    :key="p.id"
                    :index="`profile_match${index}`"
                    :headerName="p.name || p.display_name"
                    :value="p.name || p.display_name"
                    :identifier="`profile_${p.id}`"
                    :sortOptions="sortOptions"
                    :table_updating="table_updating"
                    @setSortOptions="setSortOptions"
                    column_type="profile_match"
                  ></gameplay-table-header>

                  <!-- reports -->
                  <th scope="col">
                    {{ $t("gameplay-table.table-columns.reports") }}
                  </th>

                  <!-- warnings -->
                  <th></th>

                  <!-- video interview files -->
                  <th scope="col" v-if="hasVideo">
                    {{ $t("video_files") }}
                  </th>

                  <gameplay-table-header
                    v-for="(header, index) in headers"
                    :key="header.identifier"
                    :index="`regular${index}`"
                    :headerName="header.name"
                    :identifier="header.identifier"
                    :sortOptions="sortOptions"
                    :table_updating="table_updating"
                    @setSortOptions="setSortOptions"
                    column_type="regular"
                  ></gameplay-table-header>

                  <!--custom fields -->
                  <gameplay-table-header
                    v-for="(field, index) in custom_fields"
                    :key="field.export_key"
                    :headerName="field.field_type.label"
                    :index="`custom${index}`"
                    :identifier="`field_${field.export_key}`"
                    :sortOptions="sortOptions"
                    :table_updating="table_updating"
                    @setSortOptions="setSortOptions"
                    column_type="custom"
                  ></gameplay-table-header>

                  <!--competencies -->
                  <gameplay-table-header
                    v-for="(c, index) in Object.keys(competence_headers)"
                    :key="c"
                    :headerName="competence_headers[c]"
                    :index="`competence${index}`"
                    :identifier="`comp_${c}`"
                    :sortOptions="sortOptions"
                    :table_updating="table_updating"
                    @setSortOptions="setSortOptions"
                    column_type="competence"
                  ></gameplay-table-header>
                </tr>
              </thead>

              <!-- search fields below header -->
              <tbody>
                <tr id="search">
                  <th v-for="p in profile_match_headers" :key="p.id">
                    <b-form-input
                      size="sm"
                      class="w-100"
                      :name="`${p.display_name}-${p.id}`"
                      type="number"
                      min="0"
                      max="100"
                      visibility="visible"
                      :placeholder="
                        $t(
                          'gameplay-table.search-input-placeholders.profile_match.higher_than'
                        )
                      "
                      v-model="search_values[`profile_${p.id}`]"
                      filter="percentage"
                      @keyup="setFilter(`profile_${p.id}`)"
                      @keyup.once="
                        trackMatomoEvent(
                          'CandidatesList',
                          'Filter by',
                          'profile match'
                        )
                      "
                    ></b-form-input>
                  </th>
                  <th>
                    <b-form-select
                      v-model="search_values.status"
                      @change="setFilter"
                      :options="statusFilterOptions"
                      size="sm"
                      class="completeness-select"
                    ></b-form-select>
                  </th>
                  <th></th>
                  <th v-if="hasVideo"></th>
                  <th v-for="(header, index) in headers" :key="index">
                    <b-form-input
                      size="sm"
                      :name="header.identifier"
                      :style="{ visibility: header.visibility }"
                      :type="header.type"
                      :placeholder="header.placeholder"
                      :min="header.min"
                      :max="header.max"
                      v-model="search_values[header.identifier]"
                      @keyup="setFilter(header.identifier)"
                      @keyup.once="
                        trackMatomoEvent(
                          'CandidatesList',
                          'Filter by',
                          header.value
                        )
                      "
                    ></b-form-input>
                  </th>
                  <th v-for="field in custom_fields" :key="field.export_key">
                    <b-form-input
                      size="sm"
                      v-if="field.field_type.type !== 'bool'"
                      :type="field.field_type.type"
                      :name="field.export_key"
                      :placeholder="
                        $t(
                          'gameplay-table.search-input-placeholders.default.search'
                        )
                      "
                      v-model="search_values[`field_${field.export_key}`]"
                      @keyup="setFilter(`field_${field.export_key}`)"
                      @keyup.once="
                        trackMatomoEvent(
                          'CandidatesList',
                          'Filter by',
                          'custom field'
                        )
                      "
                    ></b-form-input>
                    <b-form-select
                      v-if="field.field_type.type === 'bool'"
                      v-model="search_values[`field_${field.export_key}`]"
                      class="checkbox-select"
                      @change="setFilter(`field_${field.export_key}`)"
                      @change.once="
                        trackMatomoEvent(
                          'CandidatesList',
                          'Filter by',
                          'custom field'
                        )
                      "
                      :options="[
                        {
                          value: '',
                          text: $t(
                            'gameplay-table.search-input-placeholders.custom_checkbox.all'
                          ),
                        },
                        {
                          value: true,
                          text: $t(
                            'gameplay-table.search-input-placeholders.custom_checkbox.checked'
                          ),
                        },
                        {
                          value: false,
                          text: $t(
                            'gameplay-table.search-input-placeholders.custom_checkbox.not_checked'
                          ),
                        },
                        { value: null, text: '-' },
                      ]"
                      size="sm"
                    ></b-form-select>
                  </th>
                  <th v-for="c in Object.keys(competence_headers)" :key="c">
                    <b-form-input
                      size="sm"
                      type="number"
                      :placeholder="
                        $t(
                          'gameplay-table.search-input-placeholders.profile_match.higher_than'
                        )
                      "
                      min="0"
                      max="100"
                      v-model="search_values[`comp_${c}`]"
                      :style="{
                        visibility:
                          show_competencies === true ? 'visible' : 'hidden',
                      }"
                      @keyup="setFilter(`comp_${c}`)"
                      @keyup.once="
                        trackMatomoEvent(
                          'CandidatesList',
                          'Filter by',
                          'competence'
                        )
                      "
                    ></b-form-input>
                  </th>
                </tr>
                <gameplay-table-row
                  v-for="(player, index) in candidates"
                  :key="index"
                  :player="player"
                  :anonym_project="anonym_project"
                  :object_id="object_id"
                  :custom_fields="custom_fields"
                  :competence_fields="Object.keys(competence_headers)"
                  :profile_match_headers="profile_match_headers"
                ></gameplay-table-row>
              </tbody>
            </table>
          </div>
          <pagination
            v-model="currentPage"
            :class="{ 'pagination-disable': table_updating }"
            :records="total"
            @paginate="setPage"
            :per-page="perPage"
            :options="paginationOptions"
          ></pagination>
        </b-tab>
      </b-tabs>
      <b-alert v-else show variant="primary">
        {{ $t("gameplay-table.warning_empty") }}
      </b-alert>
    </b-card>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import Pagination from "vue-pagination-2";
import GameplayTableHeader from "./GameplayTableHeader.vue";
import GameplayTableRow from "./GameplayTableRow.vue";
import Gamelink from "./Gamelink.vue";
import FilterDates from "./FilterDates.vue";
import LoadingInline from "./LoadingInline.vue";
import MatomoMixin from "../common/matomoMixin";
import api from "../common/api";
import { RE_AUTHENTICATE } from "../common/constants";

export default {
  components: {
    GameplayTableHeader,
    GameplayTableRow,
    Gamelink,
    FilterDates,
    LoadingInline,
    Pagination,
  },
  mixins: [MatomoMixin],
  props: {
    data_ready: {
      required: true,
    },
    candidates: {
      required: true,
    },
    selected_object: {
      required: true,
    },
    object_name: {
      required: true,
    },
    gamelinks: {
      required: true,
    },
    anonym_project: {
      required: true,
    },
    object_id: {
      required: true,
    },
    custom_fields: {
      required: true,
    },
    competence_headers_data: {
      required: true,
    },
    profile_match_headers: {
      required: true,
    },
    pageSize: {
      required: true,
    },
    pageCount: {
      required: true,
    },
    perPage: {
      required: true,
    },
    total: {
      required: true,
    },
    requestParams: {
      required: true,
    },
    table_updating: {
      required: true,
    },
    allowCSV: {
      required: true,
    },
  },
  data() {
    return {
      show_competencies: false,
      sortOptions: {
        sorted_by: null,
        ascending: true,
      },
      search_values: {
        external_id: null,
        name: null,
        email: null,
        start_time_from: null,
        start_time_to: null,
        status: null,
        report: null,
      },
      column_type: "regular",
      headers: [
        {
          name: this.$t("gameplay-table.table-columns.start_time"),
          visibility: "hidden",
          identifier: "start_time",
          placeholder: this.$t(
            "gameplay-table.search-input-placeholders.default.search"
          ),
          filter: "moment",
          show: true,
        },
      ],
      timeout: null,
      currentPage: 1,
      paginationOptions: {
        chunk: 3,
        edgeNavigation: true,
        chunksNavigation: "scroll",
        texts: {
          count: "",
          first: "<<",
          last: ">>",
        },
      },
      statusCategories: [
        {
          key: "completed",
          class: "ok",
        },
        {
          key: "incomplete",
          class: "warning",
        },
        {
          key: "quit_after_onboarding",
          class: "error",
        },
        {
          key: "report_in_progress",
          class: "in-progress-finished",
        },
        {
          key: "in_progress",
          class: "in-progress",
        },
        {
          key: "video_only",
          class: "video-only",
        },
        {
          key: "unused",
          class: "unused",
        },
        {
          key: "unknown",
          class: "unknown",
        },
      ],
      showCSVPopup: false,
      csvSuccess: false,
      csvErrorCode: "",
      enableCSVButton: true,
    };
  },
  computed: {
    ...mapState("user", ["user_lang"]),
    competence_headers() {
      return this.show_competencies && this.competence_headers_data
        ? this.competence_headers_data
        : {};
    },
    inviteOnly() {
      if (this.selected_object && this.selected_object.attributes) {
        return this.selected_object.attributes.includes("INVITE_ONLY");
      }
    },
    hasVideo() {
      return (
        this.candidates.length > 0 &&
        this.candidates[0].videos &&
        this.candidates[0].videos.length > 0
      );
    },
    statusFilterOptions() {
      const options = [
        {
          value: null,
          text: this.$t("gameplay-table.search-input-placeholders.report.all"),
        },
      ];

      this.statusCategories.forEach((category) => {
        if (this.isVisible(category.class)) {
          options.push({
            value: category.class,
            text: this.$t(`dashboard.statistics_tab.legend.${category.key}`),
          });
        }
      });

      return options;
    },
  },
  watch: {
    data_ready(newValue, oldValue) {
      if (newValue === true) {
        this.setAdditionalFields();
      }
    },
  },
  methods: {
    isVisible(category) {
      return (
        category ||
        (category === "unused" && this.inviteOnly) ||
        (category === "video-only" && this.hasVideo)
      );
    },
    setFilter(statusSet) {
      const self = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const notNullValues = {};

        console.log("self.search_values: ", self.search_values);
        // Végigmegyünk a keresési értékeken
        for (const [key, value] of Object.entries(self.search_values)) {
          if ((value !== null || key.includes("field_")) && value !== "") {
            if (key === "status") {
              const matchingCategory = self.statusCategories.find(
                (cat) => cat.class === value
              );
              if (matchingCategory) {
                notNullValues.status = matchingCategory.key;
              }
            } else {
              notNullValues[key] = value;
            }

            // Profile match értékek kezelése
            if (key.includes("profile")) {
              notNullValues[key] = value / 100;
            }
          }
        }

        if (statusSet) {
          self.currentPage = 1;
        }

        self.$emit("setFilter", notNullValues, statusSet);
      }, 500);
    },
    setSortOptions(identifier) {
      this.setColumnType(identifier);
      if (this.sortOptions.sorted_by == identifier) {
        this.sortOptions.ascending = !this.sortOptions.ascending;
      } else {
        this.sortOptions.sorted_by = identifier;
        this.sortOptions.ascending = true;
      }
      this.$emit("setSortOptions", this.sortOptions);
    },
    setColumnType(type) {
      this.column_type = type;
    },
    setPagesize(value) {
      this.$emit("setPagesize", value);
    },
    setPage(page) {
      if (!this.table_updating) {
        this.currentPage = page;
        if (this.requestParams.page === this.currentPage) {
          /* prevent stack overflow */ return;
        }
        this.$emit("setPages", page);
      }
    },
    setDateFilter(from, to) {
      this.search_values.start_time_from = from;
      this.search_values.start_time_to = to;
      this.setFilter();
    },
    setAdditionalFields() {
      if (this.anonym_project === true) {
        const field = {
          name: this.$t("gameplay-table.table-columns.external_id"),
          identifier: "player_external_id",
          type: "text",
          placeholder: this.$t(
            "gameplay-table.search-input-placeholders.default.search"
          ),
          show: this.anonym_project,
        };
        this.headers.splice(0, 0, field);
      } else {
        const field1 = {
          name: this.$t("gameplay-table.table-columns.name"),
          identifier: "player_name",
          type: "text",
          placeholder: this.$t(
            "gameplay-table.search-input-placeholders.default.search"
          ),
          show: !this.anonym_project,
        };
        const field2 = {
          name: this.$t("email"),
          type: "email",
          identifier: "player_email",
          placeholder: this.$t(
            "gameplay-table.search-input-placeholders.default.search"
          ),
          show: true,
        };
        this.headers.splice(0, 0, field1);
        this.headers.splice(1, 0, field2);
      }
    },
    generateCSV() {
      api
        .generateCSV(this.object_id)
        .then(() => {
          this.csvSuccess = true;
          this.showCSVPopup = true;
          // disable CSV generation button for 5 minutes
          this.enableCSVButton = false;
          setTimeout(() => {
            this.enableCSVButton = true;
          }, 300000);
        })
        .catch((error) => {
          this.csvSuccess = false;
          this.showCSVPopup = true;
          if (error.config && error.response) {
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              RE_AUTHENTICATE(error.response.status, error.response.data);
            }
            this.csvErrorCode = error.response.status;
          } else {
            this.csvErrorCode = "Network error";
          }
        });
    },
  },
  mounted() {
    if (this.data_ready) {
      this.setAdditionalFields();
    }
  },
};
</script>
<style scoped>
.category-tooltip {
  background-color: rgba(33, 37, 41, 0.9) !important;
}
.loading-indicator {
  position: absolute;
}

.tab {
  padding: 20px;
}

.gameplay-table input {
  min-width: 125px;
}

.aggregator h2 {
  margin-top: 16px;
  margin-left: 16px;
}

.project-name {
  color: #337ab7;
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin: 16px 0 16px 0;
}

.dropdown.no-arrow-mb-4 {
  position: absolute;
}

ul.dropdown-menu.show {
  transform: translate3d(-145px, 41px, 0) !important;
  position: absolute;
}

ul.dropdown-menu.show li a {
  font-size: 12px;
}

.reports {
  line-height: 1;
  border: none;
}

.gameplay-table {
  overflow-x: scroll;
}

th,
td,
.table thead th {
  padding: 0.5rem;
  vertical-align: middle;
}

.display-options {
  display: block;
  float: none;
  color: #4e73df;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.filter {
  background-color: rgb(255, 255, 255);
  color: #4e73df;
  border-color: #4e73df;
  margin-bottom: 8px;
}

.filter:hover,
.filter:focus {
  font-weight: bold;
  color: white;
  background-color: #4e73df;
}

.category {
  display: inline-block;
  cursor: pointer;
}

.category img {
  height: 16px;
}

.category .selected {
  font-weight: bold;
  text-decoration: underline;
}

.legend {
  display: flex;
  font-size: 12px;
  float: left;
}

.legend p {
  padding-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  float: left;
}

.completeness-select {
  min-width: 94px;
}

input.completeness {
  margin-right: 5px;
}

.checkbox-select {
  min-width: 163px;
}

.competencies-checkbox {
  font-size: 14px;
}

.gameplay-table input[type="number"]::-webkit-outer-spin-button,
.gameplay-table input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gameplay-table input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

@media (min-width: 600px) {
  .competencies-checkbox {
    text-align: right;
  }
}

.competencies-checkbox input {
  margin-right: 5px;
}

.gameplay-table .loading-indicator {
  z-index: 2;
}

.table-loading-blur {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
}

@media screen and (max-width: 900px) {
  .table thead th {
    padding: 0.3rem;
  }
}

@media screen and (max-width: 500px) {
  .table thead th {
    padding: 0.15rem;
  }
}

.pagination-disable {
  pointer-events: none;
}

.csv {
  display: flex;
  gap: 10px;
  align-content: center;
}

.csv div {
  margin-bottom: 0px !important;
  font-size: 0.875rem;
  max-width: 50%;
  padding: 0.25rem 0;
}
</style>
