var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.userLoading)?_c('loading'):(!_vm.reloadNeeded)?_c('div',{attrs:{"id":"wrapper"}},[(_vm.clientTree)?_c('side-nav',{attrs:{"client":_vm.client,"clientTree":_vm.clientTree,"sidebarToggled":_vm.sidebarToggled},on:{"toggle-sidebar":function($event){return _vm.onToggleSidebar()},"scroll-to-top":function($event){return _vm.scrollToTop()}}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column",attrs:{"id":"content-wrapper"}},[_c('div',{attrs:{"id":_vm.$route.name === 'Home' || _vm.$route.name === 'Aggregator'
            ? 'content'
            : ''}},[(_vm.selectedClient !== null)?_c('navbar',{attrs:{"client":_vm.selectedClient},on:{"toggle-sidebar":function($event){return _vm.onToggleSidebar()}}}):_vm._e(),(
            _vm.$route.name === 'Dashboard'
              ? _vm.selectedClient
              : _vm.selectedClient && _vm.selectedAssessment
          )?_c('navigation',{attrs:{"index_of_clients":_vm.index_of_clients,"clientTree":_vm.clientTree,"selectedAssessment":_vm.selectedAssessment}}):_vm._e(),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('error',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}]}),(_vm.$route.name === 'Home')?_c('b-container',{attrs:{"fluid":""}},[_c('loading-inline')],1):_vm._e(),_c('router-view',{key:_vm.$route.fullPath,attrs:{"loading":_vm.userLoading,"statisticsReady":_vm.statistics_ready}})],1)])],1),_c('footer',{staticClass:"footer d-sm-none sticky-footer bg-white"},[_c('div',{staticClass:"container my-auto"},[_c('div',{staticClass:"text-right d-inline d-sm-none"},[(_vm.selectedClient !== null)?_c('img',{staticClass:"company-logo",attrs:{"src":_vm.selectedClient.logo_url,"alt":""}}):_vm._e()]),_c('div',{staticClass:"copyright text-center my-auto"}),_c('div',{staticClass:"text-left d-inline d-sm-none"},[_c('img',{staticClass:"benchmark-logo",attrs:{"src":"/img/benchmark_logo.png"}})])])])])],1):_c('page-reload',{attrs:{"countDown":_vm.countDown},on:{"reload":_vm.reloadPage}}),(_vm.showScrollToTop)?_c('a',{staticClass:"scroll-to-top rounded",on:{"click":function($event){return _vm.scrollToTop()}}},[_c('i',{staticClass:"fas fa-angle-up"})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }